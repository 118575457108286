import {Capacitor} from '@capacitor/core';
import {AppUpdate, AppUpdateAvailability, AppUpdateInfo} from '@capawesome/capacitor-app-update';
import {Injectable} from "@angular/core";
import {metrics} from "@sentry/angular";
@Injectable({providedIn: 'root'})
export class MetricManagerService {
  private updateInfo: AppUpdateInfo | null;

  constructor() {
    this.updateInfo = null;
  }

  public async incrementAppUpdateAvailable() {
    metrics.increment("UPDATE_AVAILABLE",1);
  }
  private async countUp(name:string) {
    metrics.increment(name,1);
  }
}


