import {Capacitor} from '@capacitor/core';
import {AppUpdate, AppUpdateAvailability, AppUpdateInfo} from '@capawesome/capacitor-app-update';
import {Injectable} from "@angular/core";
import {MetricManagerService} from "../metric-manager/metric-manager.service";

@Injectable({providedIn: 'root'})
export class AppUpdateManager {
  private updateInfo: AppUpdateInfo | null;

  constructor(private metricManagerService:MetricManagerService) {
    this.updateInfo = null;
  }

  async initialize() {
    try {
      const isSupported = await this.isPlatformSupported()
      if (isSupported) {
        this.updateInfo = await AppUpdate.getAppUpdateInfo();
      }
    } catch (error) {
      throw error;
    }
  }

  async isPlatformSupported() {
    const platform = Capacitor.getPlatform();
    return platform === 'android' || platform === 'ios';
  }

  async ensureUpdateInfo() {
    if (!this.updateInfo) {
      try {
        this.updateInfo = await AppUpdate.getAppUpdateInfo();
      } catch (error) {
        throw error;
      }
    }
  }

  async getCurrentAppVersion() {
    await this.ensureUpdateInfo();
    if (!this.updateInfo) {
      console.log('Update info is not initialized');
      return null;
    }
    return Capacitor.getPlatform() === 'android'
      ? this.updateInfo.currentVersionCode
      : this.updateInfo.currentVersionName;
  }

  async getAvailableAppVersion() {
    await this.ensureUpdateInfo();
    if (!this.updateInfo) {
      console.log('Update info is not initialized');
      return null;
    }
    return Capacitor.getPlatform() === 'android'
      ? this.updateInfo.availableVersionCode
      : this.updateInfo.availableVersionName;
  }

  async openAppStore() {
    try {
      await AppUpdate.openAppStore();
    } catch (error) {
      throw error;
    }
  }

  async performImmediateUpdate() {
    await this.ensureUpdateInfo();
    if (!this.updateInfo) {
      console.log('Update info is not initialized');
      return;
    }
    if (this.updateInfo.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (this.updateInfo.immediateUpdateAllowed) {
      try {
        await AppUpdate.performImmediateUpdate();
      } catch (error) {
        throw error;
      }
    }
  }

  async startFlexibleUpdate() {
    await this.ensureUpdateInfo();
    if (!this.updateInfo) {
      console.log('Update info is not initialized');
      return;
    }
    if (this.updateInfo.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (this.updateInfo.flexibleUpdateAllowed) {
      try {
        await AppUpdate.startFlexibleUpdate();
      } catch (error) {
        throw error;
      }
    }
  }

  async completeFlexibleUpdate() {
    try {
      await AppUpdate.completeFlexibleUpdate();
    } catch (error) {
      throw error;
    }
  }

  async checkForUpdatesAndForce() {
    await this.ensureUpdateInfo();
    if (!this.updateInfo) {
      console.log('Update info is not initialized');
      return;
    }
    if (this.updateInfo.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
      await this.metricManagerService.incrementAppUpdateAvailable();
      if (this.updateInfo.immediateUpdateAllowed) {
        await this.performImmediateUpdate();
      } else if (this.updateInfo.flexibleUpdateAllowed) {
        await this.startFlexibleUpdate();
      } else {
        this.promptUserToUpdate();
      }
    }
  }

  promptUserToUpdate() {
    alert('A new update is available. Please update the app to continue.');
    this.openAppStore();
    // Optionally, you can implement more sophisticated UI to force update
    // and keep prompting until the app is updated.
  }
}


